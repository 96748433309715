<template lang="html">
  <v-container fluid>
    <v-row>
      <v-col>
        <base-material-card icon="mdi-account">
          <v-toolbar flat>
            <div style="max-width:400px">
              <v-select v-model="selectSKPD" :items="skpd" label="SKPD" item-text="v" item-value="k" hide-details></v-select>
            </div>
            <span class="ml-5">Filter: </span>
            <v-switch
              v-model="filterPegawai"
              @change="loadData()"
              color="primary"
              label="Pegawai E-RK"
              class="mt-5 mx-5"
            ></v-switch>
            <v-switch
              v-model="filterSemua"
              @change="loadData()"
              color="primary"
              label="Semua Pegawai"
              class="mt-5"
            ></v-switch>
            <v-spacer></v-spacer>
            <div>
              <v-text-field v-model="search" append-icon="mdi-magnify" label="Cari" hide-details></v-text-field>
            </div>
          </v-toolbar>

          <v-data-table
            :headers="headers"
            :items="items"
            :loading="loading"
            :server-items-length="totalKeseluruhan"
            :page.sync="page"
            :items-per-page.sync="rowPerPage"
          >
            <template v-slot:item.nip="{ item }">
              <b><div v-html="item.peg_nama"></div></b>
              <div v-html="item.peg_nip"></div>
              <!-- <span>{{item.peg_nama}}</span> -->
            </template>
            <template v-slot:item.jabatan="{ item }">
              <div v-html="item.nama_jabatan"></div>
              {{ item.jenis_jabatan == 'S' ? 'Struktural' : (item.jenis_jabatan == 'F' ? 'Fungsional Umum' : (item.jenis_jabatan == 'T' ? 'Fungsional Tertentu' : '')) }} {{ item.eselon  }}<br>{{ item.nama_golongan }}
            </template>
            <template v-slot:item.job_value="{ item }">
              <b><div v-html="item.job_value"></div></b>
              <b><div v-html="item.nama_jabatan + ' ' + ( item.kelas_jabatan )"></div></b>
            </template>
            <template v-slot:item.aksi>
              <v-btn small class="my-2"><v-icon>mdi-magnify</v-icon> Detail</v-btn>
              <v-btn small><v-icon>mdi-pencil</v-icon> Status</v-btn>
              <v-btn small class="my-2"><v-icon>mdi-refresh</v-icon> Reset Password</v-btn>
            </template>
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import _g from '../../global'
import store from '@/store'
import OtherService from '@/services/OtherService'
import _ from 'lodash'
export default {
  data(){
    return{
      filterPegawai:true,
      forceUpdate:false,
      loading:false,
      detailData:{},
      rowPerPage:0,
      search:'',
      selectSKPD:'',
      skpd:[],
      page:1,

      headers:[
        {text:'No', value:'nomer', sortable:false, class:'header-index-profile'},
        {text:'NIP-Nama', value:'nip', sortable:false, class:'header-index-profile'},
        {text:'Jabatan - Pangkat', value:'jabatan', sortable:false, class:'header-index-profile'},
        {text:'Job Value', value:'job_value', sortable:false, class:'header-index-profile'},
        {text:'Aksi', value:'aksi', sortable:false, class:'header-index-profile'},
      ],
      // items:[{
      //   no:'1',
      //   nip:	'A ADELIA MARYANI <br/> 197303221992032001',
      //   jabatan: 'Struktural <br/> IV.a <br/> III/d',
      //   job_value:'1475 <br/> Kepala Seksi Kesejahteraan Sosial Kec. Bandung Wetan (9a)',
      // }],
      items:[],

      dialogReset:false,
      crudSettings:{
        title:'',
        detailTitle:'Detail',
        enableHeader:true,
        enableDetail:false,
        enableCreate:false,
      },
      totalKeseluruhan:0,
      sorts: '',
      tableValue:{},
    }
  },

  watch:{
    page(val){
      // untuk memastikan tidak reload halaman awal
      if (val > 1){
        this.loading = true
        this.tableValue.page = val
        this.updateTable(this.tableValue)
      }
    }
  },

  created(){
    let val = {itemsPerPage:10, page: 1}
    this.updateTable(val)

    OtherService.getSKPD().then(response => {
      let data = []
      _.each(response.data.data, function(value, key, obj){
        data.push({k:key, v: value})
      })
      this.skpd = data
    })
  },

  computed:{
    filterSemua:{
      get:function(){
        return !this.filterPegawai
      },
      set:function(val){
        this.filterPegawai = !val
      }
    }
  },

  mounted() {
    this.sorts = 'erk'
    this.$watch(vm => [vm.rowPerPage, vm.search, vm.selectSKPD], val => {
      this.page = 1
      this.updateTable({perpage:this.rowPerPage, skpd:this.selectSKPD, search:this.search, page:this.page, sorts: this.sorts, satuan_kerja_id: this.selectSKPD ? this.selectSKPD : ''})
    })
  },

  methods:{
    getImg(nip,ext){
      if (nip){
        let avatarImgUrl = process.env.VUE_APP_IMG_URL + nip +"."+ext
        // this.avatarImages[nip] = avatarImgUrl
        return avatarImgUrl
      }
      return ''
    },

    updateTable(val){
      this.tableValue = val? val:this.tableValue
      this.loading=true
      this.items = []
      var url = "v1/master-pegawai/get-data"
      var current = this.$store.getters["user/current"]
      var base_url_api = _g.getBaseURLAPIERK(current.year)
      const url_ = base_url_api + url
      axios.post(url_, {api: 1, 
        perpage:this.tableValue.rowPerPage,
        skpd:this.tableValue.selectSKPD,
        search:this.tableValue.search,
        page:this.tableValue.page,
        satuan_kerja_id:this.tableValue.satuan_kerja_id,
        sorts: this.tableValue.sorts}).then(response => {
        let res = response.data
        this.items = res.data
        this.totalKeseluruhan = response.data.total_keseluruhan
        this.rowPerPage = response.data.row_per_page
        // if (res.success){
        //   store.commit('snackbar/setSnack',{message: res.message, color:'success'})
        // } else {
        //   store.commit('snackbar/setSnack',{message: res.message, color:res.response})
        // }
      }).finally(()=>{
        // this.updateTable()
        this.loading=false
      }).catch(err => {
        var error = err.message ? err.message : err.response.data.message
        // this.updateTable()
        store.commit('snackbar/setSnack',{message: error, color:'error'})
      })
    },

    loadData(){
      if(this.filterSemua){
        this.sorts = 'all'
      }else{
        this.sorts = 'erk'
      }
      this.updateTable(this.sorts)
    }
  },
}
</script>

<style lang="css" scoped>
</style>
